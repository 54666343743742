export enum FateType {
  NONE = 'NONE',
  PARAGRAPH = 'PARAGRAPH',
  HEADER1 = 'HEADER1',
  HEADER2 = 'HEADER2',
  HEADER3 = 'HEADER3',
  HEADER4 = 'HEADER4',
  HEADER5 = 'HEADER5',
  HEADER6 = 'HEADER6',
  QUOTE = 'QUOTE',
  CODE = 'CODE',
  SIZE = 'SIZE',
  BOLD = 'BOLD',
  HIGHLIGHT = 'HIGHLIGHT',
  SUBSCRIPT = 'SUBSCRIPT',
  SUPERSCRIPT = 'SUPERSCRIPT',
  STRIKETHROUGH = 'STRIKETHROUGH',
  ITALIC = 'ITALIC',
  COLOR = 'COLOR',
  UNDERLINE = 'UNDERLINE',
  LINK = 'LINK',
  ALIGN_LEFT = 'ALIGN_LEFT',
  ALIGN_CENTER = 'ALIGN_CENTER',
  ALIGN_RIGHT = 'ALIGN_RIGHT',
  JUSTIFY = 'JUSTIFY',
  ORDERED_LIST = 'ORDERED_LIST',
  UNORDERED_LIST = 'UNORDERED_LIST',
  LISTITEM = 'LISTITEM',
  INDENT = 'INDENT',
  TYPEFACE = 'TYPEFACE',
}
